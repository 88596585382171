<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ titleDetalle }} {{apeYnom}}</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="itemsDispositivos"
          :loading="isLoading"
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
export default {
  name: "VerDispositivos",
  props: {
    registrosObject: {
      type: Object,
    },
  },
  data: () => ({
    search: null,
    searchIcon: enums.icons.SEARCH,
    titleDetalle: "Dispositivo/s vinculado/s al/la afiliado/a:  ",
    isLoading: false,
    apeYnom: "",
    dispositiv: [],
    headers: [
        {
          text: "Nombre del dispositivo",
          value: "dispositivo",
          sortable: false
        },
        {
          text: "Fecha de registro",
          value: "fechaRegistro",
          sortable: false
        },
        {
          text: "Marca",
          value: "marca",
          sortable: false
        },
        {
          text: "Modelo",
          value: "modelo",
          sortable: false
        },
    ],
    itemsDispositivos: []
  }),
  created() {
    this.apeYnom = this.registrosObject.apellidoNombre
    this.dispositiv = this.registrosObject.listaMovil
    this.itemsDispositivos = this.dispositiv
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert"
    }),
    closeModal() {
      this.$emit("closeModalVerDispositivos");
    },
  }
};
</script>

<style scoped>
#simple-table-container {
  overflow-x: hidden;
  width: 100%;
  overflow-y: scroll;
  max-height: 250px;
  min-height: 50px;
}
.fontsize {
  font-size: 12px;
}
</style>
