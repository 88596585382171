<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 "
      >
        <PageHeader :title="title" class="pb-3" />
        <v-card class="mx-auto mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row>
              <v-col cols="10" class="py-0" align="left">
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col cols="2" align="right" class="py-2">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="loadCantRegistros()"
              >
                <!-- Filtros comunes -->
                <v-row>
                  <!-- Fecha desde -->
                  <v-col cols="3" sm="6" md="3" class="py-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          autocomplete="not"
                          v-mask="'##/##/####'"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          outlined
                          clearable
                          :rules="
                            rules.required.concat(
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected))
                            ) || 'Formato incorrecto'
                          "
                          dense
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha hasta -->
                  <v-col cols="3" sm="6" md="3" class="py-0">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          v-mask="'##/##/####'"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          outlined
                          autocomplete="not"
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected))
                            ) || 'Formato incorrecto'
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <!-- Buequeda de afiliado -->
                <v-row>
                  <!-- Numero afiliado-->
                  <v-col cols="12" class="py-0" md="4">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      clearable
                      v-mask="'###############'"
                      label="N° de afiliado"
                      v-model.trim="numeroAfiliado"
                      @change="buscarAfil"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      clearable
                      dense
                      filled
                      disabled
                      label="Afiliado"
                      :loading="loadingBtn"
                      v-model.trim="afiliadoSelectedNom"
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" class="py-0">
                    <v-tooltip top max-width="20%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          {{ infoIcon }}
                        </v-icon>
                      </template>
                      <span
                        >Para buscar afiliado presione en la lupa o ingrese
                        número de afiliado</span
                      >
                    </v-tooltip>
                  </v-col>
                  <!-- Boton buscar por nombre o dni -->
                  <v-col cols="1" class="py-0">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          absolute
                          fab
                          small
                          color="primary"
                          @click="modalBusquedaAfiliado = true"
                        >
                          <v-icon>{{ searchIcon }}</v-icon>
                        </v-btn>
                      </template>
                      <span
                        >Buscar afiliado por nombre, apellido, DNI o
                        número</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    elevation="2"
                    small
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsRegistros"
            :loading="isLoading"
            class="elevation-1"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="verDispositivosVinculados(item)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver dispositivos vinculados</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-1 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10" md="3">
        <strong>Cantidad total de registros: {{ cantRegistros }}</strong>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2" :md="!showHelp ? 3 : 6" class="to-right pt-1 px-0 mr-3">
        <v-btn
          color="primary"
          outlined
          class="fontsize p-2"
          :disabled="itemsRegistros ? itemsRegistros.length == 0 : ''"
          @click="exportLista"
        >
          Exportar lista completa
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="modalBusquedaAfiliado"
      v-if="modalBusquedaAfiliado"
      @keydown.esc="toggleModalBusquedaAfi"
      max-width="50%"
      persistent
    >
      <BusquedaAfiliado
        @toggleModalBusquedaAfi="toggleModalBusquedaAfi"
        @findAfiliado="findAfiliado"
      ></BusquedaAfiliado>
    </v-dialog>
    <v-dialog
      v-model="openVerDispositivos"
      v-if="openVerDispositivos"
      @keydown.esc="closeModalVerDispositivos"
      max-width="65%"
      persistent
    >
      <VerDispositivos
        @closeModalVerDispositivos="closeModalVerDispositivos"
        :registrosObject="registrosObject"
      ></VerDispositivos>
    </v-dialog>
  </v-container>
</template>
<script>
import enums from "@/utils/enums/index.js";
import BusquedaAfiliado from "@/components/modules/afiliaciones/afiliados/BusquedaAfiliado.vue";
import VerDispositivos from "@/views/modules/aplicacionMovil/VerDispositivos";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import helpersExcel from "@/utils/helpers/importExportExcel";
import Ayuda from "@/components/shared/Ayuda.vue";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mask } from "vue-the-mask";
export default {
  name: "CantidadRegistrosApp",
  directives: { mask },
  components: {
    PageHeader,
    BusquedaAfiliado,
    FiltersSelected,
    VerDispositivos,
    Ayuda
  },
  data() {
    return {
      title: enums.titles.REGISTROS_APP,
      seeIcon: enums.icons.SEE,
      infoIcon: enums.icons.SNB_INFO,
      openVerDispositivos: false,
      afiliadoId: null,
      numeroAfiliado: null,
      modalBusquedaAfiliado: false,
      afiliadoSelectedNom: null,
      loadingBtn: false,
      showExpand: false,
      optionCode: enums.webSiteOptions.CANTIDAD_REGISTROS,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      calendarIcon: enums.icons.CALENDAR,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      fechaHastaSelected: null,
      fechaDesdeSelected: null,
      fechaDesde: null,
      fechaHasta: null,
      isFormValid: true,
      rules: rules,
      search: "",
      searchIcon: enums.icons.SEARCH,
      showFilters: true,
      filtersApplied: [],
      itemsRegistros: [],
      afilNombre: [],
      headers: [
        {
          text: "Documento",
          value: "tipoNroDoc",
          sortable: false
        },

        {
          text: "Apellido y nombre",
          value: "apellidoNombre",
          sortable: false
        },
        {
          text: "Fecha de registro",
          value: "fechaRegistro",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      isLoading: false,
      showHelp: false,
      showIcon: true,
      procAumTablaFacId: null,
      cantRegistros: 0,
      afiliadoId: [],
      idBen: []
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    if (this.$route.params.fromCreate) {
      this.loadCantRegistros();
    }
  },
  methods: {
    ...mapActions({
      listarRegistros: "appBenef/listarRegistros",
      getAfiliadosByNumero: "afiliaciones/getAfiliadosByNumero",
      setAlert: "user/setAlert"
    }),
    verDispositivosVinculados(item) {
      this.openVerDispositivos = true;
      this.registrosObject = item;
    },
    closeModalVerDispositivos() {
      this.openVerDispositivos = false;
    },
    findAfiliado(afiliadoSelected) {
      this.idBen = afiliadoSelected.id;
      this.afiliadoSelectedNom = afiliadoSelected.value;
      this.numeroAfiliado = afiliadoSelected.numero;
    },
    async buscarAfil(numberAfi) {
      if (this.numeroAfiliado != null) {
        try {
          this.loadingBtn = true;
          this.findAfi = await this.getAfiliadosByNumero(numberAfi);
          this.afilNombre = this.findAfi.map(x => x.value1);
          this.afiliadoId = this.findAfi.map(x => x.id)[0];
          this.loadingBtn = false;
          this.afiliadoSelectedNom = this.afilNombre[0];
        } catch (error) {
          this.loadingBtn = false;
          this.afiliadoSelectedNom = null;
        }
      } else {
        this.afiliadoSelectedNom = null;
        this.afiliadoId = null;
      }
      if (this.afilNombre.length < 1) {
        this.setAlert({
          type: "warning",
          message: "N° de afiliado inexistente"
        });
        this.numeroAfiliado = null;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    exportLista() {
      let result = [];
      this.itemsRegistros?.forEach(x =>
        result.push({
          ["Tipo y N° de documento"]: x.tipoNroDoc,
          ["Apellido y nombre"]: x.apellidoNombre,
          ["Fecha de registro"]: x.fechaRegistro,
          ["Dispositivo/s"]: x.listaMovil
            .map((dispositivo, index) => {
              const separator = index < x.listaMovil.length - 1 ? "; " : "";
              return `Fecha de registro del dispositivo: ${dispositivo.fechaRegistro}, Dispositivo: ${dispositivo.dispositivo}, Modelo: ${dispositivo.modelo}, Marca: ${dispositivo.marca}${separator}`;
            })
            .join("\n") // utilizo '.join()' para unir los elementos del array en una sola cadena
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(
        formato,
        "Listado de registros en la aplicación móvil"
      );
    },
    async loadCantRegistros() {
      this.showFilters = false;
      this.isLoading = true;
      this.customizeFiltersApplied();
      const data = {
        fechaDesde: this.parseDateToIso(this.fechaDesdeSelected),
        fechaHasta: this.parseDateToIso(this.fechaHastaSelected),
        benId: this.numeroAfiliado ? parseInt(this.afiliadoId) : 0
      };
      const res = await this.listarRegistros(data);
      this.itemsRegistros = res;
      this.cantRegistros = this.itemsRegistros.length;
      this.isLoading = false;
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(0, 0, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(1, 0, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaSelected
        });
      }
      if (this.afiliadoSelectedNom) {
        this.filtersApplied.splice(2, 0, {
          key: "benId",
          label: "Afiliado",
          model: this.afiliadoSelectedNom
        });
      }
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    findAfiliado(afiliadoSelected) {
      this.afiliadoSelectedNom = afiliadoSelected.value;
      this.afiliadoId = afiliadoSelected.id;
      this.numeroAfiliado = afiliadoSelected.numero;
    },
    toggleModalBusquedaAfi() {
      this.modalBusquedaAfiliado = !this.modalBusquedaAfiliado;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.fontsize {
  font-size: 12px;
}
.fontColor {
  font-weight: bold !important;
  color: rgb(0, 0, 0) !important;
  text-align: center;
}
.invalidConfigText {
  color: red;
}
.no-upper-case {
  text-transform: none;
}
</style>
